import { search, spaceClient } from 'lib/axios'

import getAcceptLanguageByLocale from '../../../lib/getAcceptLanguageByLocale'
import getLocaleHeaderByLocale from '../../../lib/getLocaleHeaderByLocale'

const API_SPACES_PROFILE = '/api/v1/spaces'
const API_SPACE_DETAIL = '/api/app/v1/spaces/'

export const getSpaces = (params) =>
  search.get(API_SPACES_PROFILE, {
    params: {
      user_id: params.user_id,
      latitude: params.lat,
      longitude: params.lon,
      booked_from: params.booked_from,
      booked_to: params.booked_to,
      small_locker_num: params.bag_size,
      large_locker_num: params.suitcase_size,
      has_wifi: params.has_wifi,
      has_charge: params.has_charge,
      available_in_english: params.available_in_english,
      no_smoking: params.no_smoking,
      has_elevator: params.has_elevator,
      available_24_hour: params.available_24_hour,
      can_book_on_the_day: params.can_book_on_the_day,
      time_zone: params.time_zone,
      os_name: params.os_name,
      browser_name: params.browser_name,
      locale: params.locale,
    },
  })

export const getSpaceDetail = (spaceId, locale?) => {
  const _locale = getLocaleHeaderByLocale(locale || 'ja')
  const _acceptLanguage = getAcceptLanguageByLocale(locale || 'ja')
  return spaceClient.get(API_SPACE_DETAIL + spaceId, {
    headers: { 'Accept-Language': _acceptLanguage, LOCALE: _locale },
  })
}
