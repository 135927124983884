import { authClient } from 'lib/axios'
import { parseCookies } from 'nookies'

const API_USERS_PROFILE = '/api/app/v1/users/profile'
const API_CREDIT_CARD = '/api/app/v1/users/credit_card'
const API_VERIFY_PHONE_NUMBER = '/api/app/v1/users/verify_phone_otp'
const API_CREATE_SETUP_INTENT = '/api/app/v1/users/setup_intent'

export const getUser = (accessToken) =>
  authClient.get(API_USERS_PROFILE, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

export const updateUser = async (params) =>
  await authClient.patch(
    API_USERS_PROFILE,
    {
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      user_image_url: params.userImage,
      country_id: params.country_id || 1050,
    },
    {
      headers: {
        Authorization: `Token ${params.accessToken}`,
      },
    }
  )

type VerifyPhoneNumberParams = {
  phone_number: string
  code: string
  session_info: string
}

export const verifyPhoneNumber = async (params: VerifyPhoneNumberParams) => {
  const cookies = parseCookies()

  await authClient.post(API_VERIFY_PHONE_NUMBER, params, {
    headers: {
      Authorization: `Token ${cookies.jwt_token}`,
    },
  })
}

export const updatePhoneNumber = async (params) =>
  await authClient.patch(
    API_USERS_PROFILE,
    {
      phone_number: params.phoneNumber,
    },
    {
      headers: {
        Authorization: `Token ${params.accessToken}`,
      },
    }
  )

export const deleteUser = (payload) =>
  authClient.delete(API_USERS_PROFILE, {
    headers: {
      Authorization: `Token ${payload.accessToken}`,
    },
    data: {
      delete_reason: payload.deleteReason,
    },
  })

export const getCreditCard = (accessToken) =>
  authClient.get(API_CREDIT_CARD, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

export const createSetupIntent = (accessToken) =>
  authClient.post(
    API_CREATE_SETUP_INTENT,
    {},
    {
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    }
  )

export const updateCreditCard = (stripePaymentMethodId, accessToken) =>
  authClient.patch(
    API_CREDIT_CARD,
    { stripe_payment_method_id: stripePaymentMethodId },
    {
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    }
  )

export const deleteCreateCard = (accessToken) =>
  authClient.delete(API_CREDIT_CARD, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
