import { AppProps } from 'next/app'
import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import { Provider as NextAuthProvider } from 'next-auth/client'
import { AuthProvider } from 'providers/AuthProvider'
import { IntercomProvider } from 'react-use-intercom'
import moment from 'moment'
import { LoadScriptNext } from '@react-google-maps/api'

import { initializeApp } from 'firebase/app'
import { ToastContainer } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { parseCookies } from 'nookies'

import { appWithTranslation } from 'next-i18next'
import { createStore } from 'state/createStore'
import { refreshErrors } from 'state/ducks/app/actions'
import { fetchUserDataRequest } from 'state/ducks/user/actions'
import * as gtag from 'lib/gtag'
import { authClient } from 'lib/axios'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-multi-carousel/lib/styles.css'
import 'react-circular-progressbar/dist/styles.css'
import '../styles/globals.scss'
import '../styles/calendar.scss'

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DB_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

initializeApp(firebaseConfig)

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

declare global {
  interface Window {
    Intercom: any
  }
}

const store = createStore()
const googleMapLibraries = ['places']

// @ts-ignore Property 'err' does not exist
function app({ Component, pageProps, err }: AppProps) {
  const cookies = parseCookies()
  const router = useRouter()

  /**
   * Intercomの表示判定
   * @param route string router.route
   * @returns boolean true / false 表示する場合はtrue, 非表示はfalse
   */
  const isShowIntercom = (route: string): boolean => {
    const paths = ['/', '/jpn/city/[...slug]', '/dedicated/expo2025']
    return paths.includes(route)
  }

  useEffect(() => {
    const handleRouteChange = (_) => {
      store.dispatch(refreshErrors())
    }
    const handleRouteChangeComplete = (_) => {}
    const handleRouteChangeError = (_) => {}
    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router, store])

  useEffect(() => {
    if (!gtag.GA_TRACKING_ID) return

    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: !isShowIntercom(router.route),
    })
  }, [router])

  useEffect(() => {
    if (cookies.jwt_token) {
      store.dispatch(fetchUserDataRequest(cookies.jwt_token))
    }
  }, [cookies.jwt_token])

  const handleCheckMaintainStatus = useCallback(async () => {
    try {
      if (router.pathname === '/maintain') return

      const result = await authClient.get('/maintenance')

      const { status, maintenance_start_time, maintenance_end_time } = result.data

      if (status === 'maintenance') {
        let startDate = maintenance_start_time ? moment(maintenance_start_time).valueOf() : ''
        let endDate = maintenance_end_time ? moment(maintenance_end_time).valueOf() : ''
        router.replace(`/maintain?start=${startDate}&end=${endDate}`)
      }
    } catch (error) {
      console.log(error)
    }
  }, [router])

  useEffect(() => {
    handleCheckMaintainStatus()
  }, [handleCheckMaintainStatus])

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <NextAuthProvider session={pageProps.session}>
          <AuthProvider>
            {/* @see: Workaround for https://github.com/vercel/next.js/issues/8592 */}
            <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
              <LoadScriptNext
                googleMapsApiKey={process.env.GOOGLE_MAP_KEY}
                libraries={googleMapLibraries}
                loadingElement={() => <></>}
              >
                <ToastContainer theme="colored" hideProgressBar autoClose={2000} />
                <Component {...pageProps} err={err}></Component>
              </LoadScriptNext>
            </IntercomProvider>
          </AuthProvider>
        </NextAuthProvider>
      </Elements>
    </Provider>
  )
}

export default appWithTranslation(app)
