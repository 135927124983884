import {
  FETCH_SET_USER_ID_REQUEST,
  SET_USER_DATA,
  FETCH_USER_REQUEST,
  FETCH_UPDATE_USER_REQUEST,
  FETCH_DELETE_USER_REQUEST,
  FETCH_UPDATE_CREDIT_CARD_REQUEST,
  FETCH_DELETE_CREDIT_CARD_REQUEST,
  FETCH_CREDIT_CARD_REQUEST,
  SET_CREDIT_CARD_DATA,
} from './types'
import { CreditCard, User } from './models'

export const fetchSetUserIdRequest = (
  accessToken: string
): Readonly<{
  type: 'FETCH_SET_USER_ID_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FETCH_SET_USER_ID_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const fetchUserDataRequest = (
  accessToken: string
): Readonly<{
  type: 'FETCH_USER_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FETCH_USER_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const setUserData = (
  result: User
): Readonly<{
  type: 'SET_USER_DATA'
  payload: {
    user: {
      result: User
    }
  }
}> => {
  return {
    type: SET_USER_DATA,
    payload: {
      user: { result },
    },
  }
}

export const fetchUpdateUserRequest = (
  accessToken: string,
  firstName: string,
  lastName: string,
  email: string,
  userImage: string,
  country_id: number
): Readonly<{
  type: 'FETCH_UPDATE_USER_REQUEST'
  payload: {
    accessToken: string
    firstName: string
    lastName: string
    email: string
    userImage: string
    country_id: number
  }
}> => {
  return {
    type: FETCH_UPDATE_USER_REQUEST,
    payload: {
      accessToken,
      firstName,
      lastName,
      email,
      userImage,
      country_id,
    },
  }
}

export const fetchDeleteUserRequest = (
  accessToken: string,
  deleteReason: string
): Readonly<{
  type: 'FETCH_DELETE_USER_REQUEST'
  payload: {
    accessToken: string
    deleteReason: string
  }
}> => {
  return {
    type: FETCH_DELETE_USER_REQUEST,
    payload: {
      accessToken,
      deleteReason,
    },
  }
}

export const fetchUpdateCreditCardRequest = (
  stripePaymentMethodId: string,
  accessToken: string
): Readonly<{
  type: 'FETCH_UPDATE_CREDIT_CARD_REQUEST'
  payload: {
    stripePaymentMethodId: string,
    accessToken: string
  }
}> => {
  return {
    type: FETCH_UPDATE_CREDIT_CARD_REQUEST,
    payload: {
      stripePaymentMethodId,
      accessToken,
    },
  }
}

export const fetchDeleteCreditCardRequest = (
  accessToken: string
): Readonly<{
  type: 'FETCH_DELETE_CREDIT_CARD_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FETCH_DELETE_CREDIT_CARD_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const fetchCreditCardDataRequest = (
  accessToken: string
): Readonly<{
  type: 'FETCH_CREDIT_CARD_REQUEST'
  payload: {
    accessToken: string
  }
}> => {
  return {
    type: FETCH_CREDIT_CARD_REQUEST,
    payload: {
      accessToken,
    },
  }
}

export const setCreditCardData = (
  result: CreditCard
): Readonly<{
  type: 'SET_CREDIT_CARD_DATA'
  payload: {
    creditCard: {
      result: CreditCard
    }
  }
}> => {
  return {
    type: SET_CREDIT_CARD_DATA,
    payload: {
      creditCard: { result },
    },
  }
}
